import React, { useState } from "react"
import { Link } from "gatsby"

import "./styles.scss"

const Footer = () => {
  return (
    <footer>
      <nav className="footer">
        <ul className="footer-list">
          <li>
            <Link
              className="footer-list-links"
              activeClassName="footer-list-links-active"
              to="/"
            >
              Accueil
            </Link>
          </li>
          <li>
            <Link
              className="footer-list-links"
              activeClassName="footer-list-links-active"
              to="/historique/"
            >
              Historique
            </Link>
          </li>
          <li>
            <Link
              className="footer-list-links"
              activeClassName="footer-list-links-active"
              to="/genealogie/"
            >
              Généalogie
            </Link>
          </li>
          <li>
            <Link
              className="footer-list-links"
              activeClassName="footer-list-links-active"
              to="/vin/"
            >
              Vin
            </Link>
          </li>
          <li>
            <Link
              className="footer-list-links"
              activeClassName="footer-list-links-active"
              to="/liens/"
            >
              Liens
            </Link>
          </li>
          <li>
            <Link
              className="footer-list-links"
              activeClassName="footer-list-links-active"
              to="/contact/"
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Footer
