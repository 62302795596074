import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Nav from "../Nav"
import blason from "./blason-decazes-small.png"
import Footer from "../Footer"

import "./styles.scss"

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <header>
        <div className="BG-mobile" />
        <Link to="/">
          <img src={blason} className="logo" alt="duc-decazes-blason-icon" />
        </Link>
        <Nav />
      </header>
      <main className="layout-content">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
