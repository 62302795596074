import React, { useState } from "react"
import { Link } from "gatsby"

import "./styles.scss"

const Nav = () => {
  const [burgerToggle, setBurgerToggle] = useState(false)

  const handleToggleMenu = () => {
    setBurgerToggle(!burgerToggle)
  }

  return (
    <>
      <nav className="nav">
        <ul className="nav-list">
          <li>
            <Link
              className="nav-list-links"
              activeClassName="nav-list-links-active"
              to="/historique/"
            >
              Historique
            </Link>
          </li>
          <li>
            <Link
              className="nav-list-links"
              activeClassName="nav-list-links-active"
              to="/genealogie/"
            >
              Généalogie
            </Link>
          </li>
          <li>
            <Link
              className="nav-list-links"
              activeClassName="nav-list-links-active"
              to="/vin/"
            >
              Vin
            </Link>
          </li>
          <li>
            <Link
              className="nav-list-links"
              activeClassName="nav-list-links-active"
              to="/liens/"
            >
              Liens
            </Link>
          </li>
          <li>
            <Link
              className="nav-list-links"
              activeClassName="nav-list-links-active"
              to="/contact/"
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
      <div className="burger-wrap" onClick={handleToggleMenu}>
        <div className={burgerToggle ? "burger active" : "burger"}>
          <span />
          <span />
          <span />
        </div>
      </div>
      <div>
        <nav>
          <ul className={burgerToggle ? "mobile-menu open" : "mobile-menu"}>
            <li
              className={
                burgerToggle ? "mobile-menu__item active1" : "mobile-menu__item"
              }
            >
              <Link to="/historique/">historique</Link>
            </li>
            <li
              className={
                burgerToggle ? "mobile-menu__item active2" : "mobile-menu__item"
              }
            >
              <Link to="/genealogie/">généalogie</Link>
            </li>
            <li
              className={
                burgerToggle ? "mobile-menu__item active3" : "mobile-menu__item"
              }
            >
              <Link to="/vin/">vin</Link>
            </li>
            <li
              className={
                burgerToggle ? "mobile-menu__item active4" : "mobile-menu__item"
              }
            >
              <Link to="/liens/">liens</Link>
            </li>
            <li
              className={
                burgerToggle ? "mobile-menu__item active5" : "mobile-menu__item"
              }
            >
              <Link to="/contact/">contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Nav
